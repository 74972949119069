import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { select, slice, load, submit, reset, loadEmployee } from './slice'
import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useInjectSaga } from 'app/hooks/useInjectSaga'
import saga from './saga'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useQuery } from 'hooks/useQuery'
import Form, { Values } from './Form'
import { useFormikRef } from 'hooks/useFormikRef'
import { useFormikSetSubmitting } from 'hooks/useFormikSetSubmitting'
import format from 'date-fns/format'
import { BreadcrumbsContext, Crumb, HOME } from 'context/Breadcrumbs'

const Loading = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > span {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`

const validationSchema = yup.object({
  employee: yup.object().required().label('Employee'),
  startDate: yup.date().required().label('Start Date'),
  endDate: yup.date().min(yup.ref('startDate')).required().label('End Date'),
})

type Params = Record<'id' | 'from', string | undefined>

const crumbs: ReadonlyArray<Crumb> = [
  HOME,
  { href: '/employee-contract', children: 'Employee Contract' },
]

export const Index: FC = () => {
  const { setCrumbs } = useContext(BreadcrumbsContext)
  useInjectReducer(slice.name, slice.reducer)
  useInjectSaga(slice.name, saga)
  const params = useParams<Params>()
  const query = useQuery()
  const navigate = useNavigate()
  const id = params.id ? parseInt(params.id, 10) : 0
  const employeeId = query.get('employeeId')
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(query.get('employeeId')!, 10)
    : undefined
  const { employees, data, status, errorMessage } = useAppSelector(select)
  const [prev, setPrev] = useState(status)
  const dispatch = useAppDispatch()
  const [einit, setEinit] = useState(employeeId ? false : true)

  useEffect(() => {
    if (id <= 0) {
      setCrumbs([
        ...crumbs,
        { href: `/employee-contract/create`, children: 'Create' },
      ])
    } else if (data && data.id === id) {
      setCrumbs([
        ...crumbs,
        { href: `/employee-contract/edit/${id}`, children: data.employee.name },
      ])
    } else {
      setCrumbs([
        ...crumbs,
        { href: `/employee-contract/edit/${id}`, children: 'Edit' },
      ])
    }
  }, [data, id, setCrumbs])

  const formik = useFormikRef<Values>()
  useFormikSetSubmitting(formik, status === 'submitting')

  useEffect(() => {
    if (data && data.id === id && prev === 'loading' && status === 'idle') {
      formik.current?.resetForm({
        values: {
          employee: { ...data.employee, label: data.employee.name },
          activeContract: data.employee.activeContract || null,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
        },
      })
    }
    setPrev(status)
  }, [prev, status, data, id, formik])

  useEffect(() => {
    if (employees.find((it) => it.id === employeeId)) {
      setEinit(true)
    }
  }, [employeeId, employees])

  useEffect(() => {
    if (id) {
      dispatch(load(id))
    }
    dispatch(loadEmployee({ id: employeeId }))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, id, employeeId])

  const loading = status === 'loading' || (id && data && data.id !== id)

  return (
    <Container
      maxWidth="xl"
      sx={
        loading
          ? {
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : {
              minHeight: '100vh',
            }
      }
    >
      {loading || !einit ? (
        <Loading>
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Loading>
      ) : (
        <Formik
          innerRef={formik}
          validationSchema={validationSchema}
          initialValues={
            data && data.id === id
              ? {
                  employee:
                    employees.find((it) => it.id === employeeId) || null,
                  startDate: new Date(data.startDate),
                  endDate: new Date(data.endDate),
                }
              : {
                  employee:
                    employees.find((it) => it.id === employeeId) || null,
                  startDate: null,
                  endDate: null,
                }
          }
          onSubmit={(values) => {
            dispatch(
              submit({
                employeeId: values.employee!.id,
                startDate: format(values.startDate!, 'yyyy-MM-dd'),
                endDate: format(values.endDate!, 'yyyy-MM-dd'),
              })
            )
          }}
        >
          {Form}
        </Formik>
      )}
      <Dialog
        open={status === 'success' || status === 'failed'}
        onClose={() => {
          if (status === 'failed') {
            dispatch(reset())
          } else if (status === 'success') {
            if (params.from) {
              navigate(params.from, { replace: true })
            } else {
              navigate(-1)
            }
          }
        }}
      >
        <DialogTitle>
          {status === 'success' ? 'Data saved successfully' : 'Error'}
        </DialogTitle>
        {errorMessage && (
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              if (status === 'failed') {
                dispatch(reset())
              } else if (status === 'success') {
                if (params.from) {
                  navigate(params.from, { replace: true })
                } else {
                  navigate(-1)
                }
              }
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Index
