import React from 'react'
import {
  Autocomplete as A,
  AutocompleteProps as AC,
  AutocompleteValue,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { useField } from 'formik'

export interface AutocompleteProps<
  T,
  M extends boolean | undefined = undefined,
  DC extends boolean | undefined = undefined,
  F extends boolean = false
> extends Omit<
    AC<T, M, DC, F>,
    'value' | 'onChange' | 'renderInput' | 'onChange'
  > {
  readonly name: string
  readonly label?: TextFieldProps['label']
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function Autocomplete<
  T,
  M extends boolean | undefined = undefined,
  DC extends boolean | undefined = undefined,
  F extends boolean = false
>({ name, label, ...props }: AutocompleteProps<T, M, DC, F>) {
  const [field, meta, helper] = useField<AutocompleteValue<T, M, DC, F>>(name)
  return (
    <A
      {...props}
      onChange={(_, v) => helper.setValue(v)}
      value={field.value}
      onBlur={() => helper.setTouched(true)}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={meta.touched ? meta.error : undefined}
          error={meta.touched && !!meta.error}
          label={label}
        />
      )}
    />
  )
}

export default Autocomplete
