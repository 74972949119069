import { HOME, useSetBreadcrumbs } from 'context/Breadcrumbs'
import ExpiringContract from 'containers/EmployeeContractExpiring'
import React, { FC } from 'react'

const crumbs = [HOME]

const Dashboard: FC = () => {
  useSetBreadcrumbs(crumbs)
  return (
    <>
      <ExpiringContract />
    </>
  )
}

export default Dashboard
