import React, { FC } from 'react'
import {
  AppBar as A,
  Breadcrumbs,
  IconButton,
  IconButtonProps,
  Link,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Menu } from '@mui/icons-material'
import { Link as L } from 'react-router-dom'
import { useBreadcrumbs } from 'context/Breadcrumbs'

export interface AppBarProps {
  readonly onMenuClick: IconButtonProps['onClick']
  readonly drawerWidth: number
}

const Crumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-separator {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  .MuiLink-root {
    display: flex;
    align-items: center;
  }
`

const AppBar: FC<AppBarProps> = ({ onMenuClick, drawerWidth }) => {
  const crumbs = useBreadcrumbs()
  return (
    <A
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          onClick={onMenuClick}
          sx={{ display: { sm: 'none' }, mr: 2 }}
        >
          <Menu />
        </IconButton>
        <Crumbs aria-label="breadcrumb" sx={{ alignItems: 'center' }}>
          {crumbs.map((it, i) => {
            if (it.href) {
              return (
                <Link
                  underline="hover"
                  color="HighlightText"
                  component={L}
                  to={it.href}
                  key={i}
                >
                  {it.children}
                </Link>
              )
            }
            return (
              <Typography color="HighlightText" component="div" key={i}>
                {it.children}
              </Typography>
            )
          })}
        </Crumbs>
        {/* <Typography component="h1" variant="h6">
          Dashboard
        </Typography> */}
      </Toolbar>
    </A>
  )
}

export default AppBar
