import client, { tryRequest } from 'utils/axios'
import type { SuccessPayload, Values } from './slice'

export const login = (values: Values) => {
  return tryRequest(
    client.request<SuccessPayload>({
      url: 'auth/login',
      method: 'POST',
      auth: values,
    })
  )
}
