import { Home } from '@mui/icons-material'
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import type { LinkProps } from 'react-router-dom'

export interface Crumb {
  readonly href?: LinkProps['to']
  readonly children?: ReactNode
}

export interface SetCrumbs {
  (crumbs: ReadonlyArray<Crumb>): void
}

export interface BreadcrumbsProps {
  readonly crumbs: ReadonlyArray<Crumb>
  readonly setCrumbs: SetCrumbs
}

export const BreadcrumbsContext = createContext<BreadcrumbsProps>({
  crumbs: [],
  setCrumbs: () => undefined,
})

export interface BreadcrumbsContextProviderProps {
  readonly children: ReactNode
}

const BreadcrumbsContextProvider: FC<BreadcrumbsContextProviderProps> = ({
  children,
}) => {
  const [crumbs, setCrumbs] = useState<ReadonlyArray<Crumb>>([])
  return (
    <BreadcrumbsContext.Provider value={{ crumbs, setCrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export const HOME: Crumb = {
  href: '/',
  children: <Home fontSize="medium" />,
}

export const useBreadcrumbs = (): ReadonlyArray<Crumb> => {
  const { crumbs } = useContext(BreadcrumbsContext)
  return crumbs
}

export const useSetBreadcrumbs = (crumbs: ReadonlyArray<Crumb>) => {
  const { setCrumbs } = useContext(BreadcrumbsContext)
  useEffect(() => {
    setCrumbs(crumbs)
  }, [crumbs])
}

export default BreadcrumbsContextProvider
