import Dashboard from 'features/dashboard'
import Login from 'features/login/Login'
import EmployeeList from 'containers/EmployeeList'
import EmployeeForm from 'containers/EmployeeForm'
import EmployeeDetail from 'containers/EmployeeDetail'
import EmployeeContractList from 'containers/EmployeeContractList'
import EmployeeContractDetail from 'containers/EmployeeContractDetail'
import EmployeeContractForm from 'containers/EmployeeContractForm'
import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import MainLayout from './MainLayout'

const MainRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="employee" element={<EmployeeList />} />
        <Route path="employee/create" element={<EmployeeForm />} />
        <Route path="employee/edit/:id" element={<EmployeeForm />} />
        <Route path="employee/:id" element={<EmployeeDetail />} />
        <Route path="employee-contract" element={<EmployeeContractList />} />
        <Route
          path="employee-contract/create"
          element={<EmployeeContractForm />}
        />
        <Route
          path="employee-contract/edit/:id"
          element={<EmployeeContractForm />}
        />
        <Route
          path="employee-contract/:id"
          element={<EmployeeContractDetail />}
        />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}

export default MainRoute
