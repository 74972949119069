import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

export interface Employee {
  readonly id: number
  readonly name: string
  readonly email: string
  readonly activeContract?: {
    readonly id: number
    readonly startDate: number
    readonly endDate: number
  }
}

export interface FailedPayload {
  readonly message: string
}

export interface State {
  readonly data?: Employee
  readonly errorMessage?: string
  readonly status: 'idle' | 'loading' | 'success' | 'failed'
}

const initialState: State = {
  status: 'idle',
}

export const slice = createSlice({
  name: 'employeeDetail',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    load: (state, { payload }: PayloadAction<number>) => {
      state.status = 'loading'
      state.data = undefined
    },
    loaded: (state, { payload }: PayloadAction<Employee>) => {
      state.status = 'idle'
      state.data = payload
    },
    failed: (state, { payload }: PayloadAction<FailedPayload>) => {
      state.status = 'failed'
      state.errorMessage = payload.message
    },
    reset: (state) => {
      state.status = 'idle'
      state.errorMessage = undefined
    },
  },
})

export const { load, loaded, failed, reset } = slice.actions

export const select = (state: RootState) => state.employeeDetail || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
