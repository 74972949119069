import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  TextField,
  Toolbar,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import React, { FC, useContext, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { select, slice, load, reset } from './slice'
import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useInjectSaga } from 'app/hooks/useInjectSaga'
import saga from './saga'
import { Link, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { BreadcrumbsContext, Crumb, HOME } from 'context/Breadcrumbs'

const crumbs: ReadonlyArray<Crumb> = [
  HOME,
  { href: '/employee', children: 'Employee' },
]

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > span,
  & > div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`

type Params = Record<'id', string | undefined>

export const Index: FC = () => {
  const { setCrumbs } = useContext(BreadcrumbsContext)
  useInjectReducer(slice.name, slice.reducer)
  useInjectSaga(slice.name, saga)
  const params = useParams<Params>()
  const id = params.id ? parseInt(params.id, 10) : 0
  const { data, status, errorMessage } = useAppSelector(select)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!data || data.id !== id) {
      setCrumbs([...crumbs, { href: `/employee/${id}`, children: 'Detail' }])
    } else {
      setCrumbs([...crumbs, { href: `/employee/${id}`, children: data.name }])
    }
  }, [data, id, setCrumbs])

  useEffect(() => {
    if (id) {
      dispatch(load(id))
    }
    return () => {
      dispatch(reset())
    }
  }, [dispatch, id])

  const loading = status === 'loading' || (id && data && data.id !== id)

  return (
    <Container
      maxWidth="xl"
      sx={
        loading
          ? {
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : {
              minHeight: '100vh',
            }
      }
    >
      <Toolbar>
        <Button variant="outlined" component={Link} to={`/employee/edit/${id}`}>
          Edit
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to={{
            pathname: `/employee-contract/create`,
            search: `?employeeId=${id}`,
          }}
          sx={{ ml: 1 }}
        >
          Create Contract
        </Button>
      </Toolbar>
      {loading || !data ? (
        <Wrapper>
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Wrapper>
      ) : (
        <Wrapper>
          <TextField
            variant="filled"
            label="ID"
            value={data.id}
            InputProps={{ readOnly: true }}
          />
          <TextField
            variant="filled"
            label="Name"
            value={data.name}
            InputProps={{ readOnly: true }}
          />
          <TextField
            variant="filled"
            label="Email"
            value={data.email}
            InputProps={{ readOnly: true }}
          />
          {!!data.activeContract && (
            <>
              <TextField
                variant="filled"
                label="Contract Start"
                value={format(
                  new Date(data.activeContract.startDate),
                  'eeee, dd MMMM yyyy'
                )}
                InputProps={{ readOnly: true }}
              />
              <TextField
                variant="filled"
                label="Contract End"
                value={format(
                  new Date(data.activeContract.endDate),
                  'eeee, dd MMMM yyyy'
                )}
                InputProps={{ readOnly: true }}
              />
            </>
          )}
        </Wrapper>
      )}
      <Dialog
        open={status === 'failed'}
        onClose={() => {
          dispatch(reset())
        }}
      >
        <DialogTitle>{'Error'}</DialogTitle>
        {errorMessage && (
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(reset())
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Index
