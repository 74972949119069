import client, { tryRequest } from 'utils/axios'
import type { Values, Employee } from './slice'

export const loadData = (id: number) => {
  return tryRequest(client.get<Employee>(`employee/${id}`))
}

export const create = (values: Values) => {
  return tryRequest(client.post<Employee>(`employee`, values))
}

export const update = (id: number, values: Values) => {
  return tryRequest(client.patch<Employee>(`employee/${id}`, values))
}
