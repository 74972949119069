import React, { FC, ReactNode } from 'react'
import { createTheme, ThemeProvider as TP } from '@mui/material/styles'

export interface ThemeProviderProps {
  readonly children: ReactNode
}

const theme = createTheme({
  palette: {
    primary: {
      50: '#e3f2fd',
      100: '#bbdefb',
      200: '#90caf9',
      300: '#64b5f6',
      400: '#42a5f5',
      500: '#2095f3',
      600: '#1e87e5',
      700: '#1976d2',
      800: '#1564c0',
      900: '#0d46a1',
      main: '#1976d2',
    },
    secondary: {
      50: '#faf1e1',
      100: '#f4dbb3',
      200: '#ecc483',
      300: '#e5ad54',
      400: '#e19d34',
      500: '#dc8e20',
      600: '#d8831d',
      700: '#d27619',
      800: '#cb6816',
      900: '#bf5213',
      main: '#d27619',
    },
  },
})

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  return <TP theme={theme}>{children}</TP>
}

export default ThemeProvider
