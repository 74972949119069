import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
} from '@mui/material'
import Container from '@mui/material/Container'
import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { select, slice, load, search } from './slice'
import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useInjectSaga } from 'app/hooks/useInjectSaga'
import saga from './saga'
import { Link } from 'react-router-dom'
import { Edit, Launch } from '@mui/icons-material'
import SearchInput from 'components/SearchInput'
import { Crumb, HOME, useSetBreadcrumbs } from 'context/Breadcrumbs'

const crumbs: ReadonlyArray<Crumb> = [
  HOME,
  { href: '/employee-contract', children: 'Employee Contract' },
]

export const Index: FC = () => {
  useSetBreadcrumbs(crumbs)
  useInjectReducer(slice.name, slice.reducer)
  useInjectSaga(slice.name, saga)
  const { data } = useAppSelector(select)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(load({ page: 1, perPage: 20, search: '' }))
  }, [dispatch])

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          component={Link}
          to="/employee-contract/create"
        >
          Create
        </Button>
        <SearchInput
          placeholder="Search..."
          onChange={(e) => {
            dispatch(search(e.target.value))
          }}
        />
      </Toolbar>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Employee</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((it) => (
              <TableRow key={it.id}>
                <TableCell>{it.id}</TableCell>
                <TableCell>
                  {it.employee.name}
                  <IconButton
                    size="small"
                    component={Link}
                    to={`/employee/${it.employee.id}`}
                  >
                    <Launch />
                  </IconButton>
                </TableCell>
                <TableCell>{it.startDate}</TableCell>
                <TableCell>{it.endDate}</TableCell>
                <TableCell>
                  <Tooltip title="Detail">
                    <IconButton
                      component={Link}
                      to={`/employee-contract/${it.id}`}
                    >
                      <Launch />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      component={Link}
                      to={{
                        pathname: `/employee-contract/edit/${it.id}`,
                        search: `?employeeId=${it.employee.id}`,
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 50]}
        component="div"
        count={data?.count || 0}
        rowsPerPage={data?.perPage || 20}
        page={(data?.page || 1) - 1}
        onPageChange={(_, page) => {
          dispatch(
            load({
              page: page + 1,
              perPage: data?.perPage || 20,
              search: data?.search || '',
            })
          )
        }}
        onRowsPerPageChange={(event) => {
          dispatch(
            load({
              page: 1,
              perPage: parseInt(event.target.value, 10),
              search: data?.search || '',
            })
          )
        }}
      />
    </Container>
  )
}

export default Index
