import { Box, CircularProgress, Toolbar } from '@mui/material'
import { useAppSelector } from 'app/hooks'
import AppBar from 'components/AppBar'
import Drawer from 'components/Drawer'
import { select } from 'features/session/slice'
import React, { FC, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const MainLayout: FC = () => {
  const { status } = useAppSelector(select)
  const navigate = useNavigate()
  useEffect(() => {
    if (status === 'failed' || status === 'removed') {
      navigate({ pathname: '/login' }, { replace: true })
    }
  }, [status, navigate])

  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  if (status !== 'success') {
    return <CircularProgress />
  }

  const drawerWidth = 240

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar drawerWidth={drawerWidth} onMenuClick={toggle} />
      <Drawer drawerWidth={drawerWidth} onClose={toggle} open={open} />
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout
