import type { PayloadAction } from '@reduxjs/toolkit'
import type { Paged } from 'common-types'
import type { SagaIterator } from 'redux-saga'
import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import type { Response } from 'utils/axios'
import { create, loadData, update, loadEmployees } from './services'
import {
  success,
  failed,
  load,
  Values,
  loaded,
  select as s,
  State,
  submit,
  EmployeeContract,
  SearchEmployeePayload,
  loadEmployee,
  searchEmployee,
  Employee,
  employees,
} from './slice'

function* loadDataSaga({ payload }: PayloadAction<number>): SagaIterator {
  const { data, error }: Response<EmployeeContract> = yield call(
    loadData,
    payload
  )
  if (data) {
    yield put(loaded(data))
  } else {
    yield put(failed({ message: error.message }))
  }
}

function* submitSaga({ payload }: PayloadAction<Values>): SagaIterator {
  const state: State = yield select(s)
  let response: Response<EmployeeContract>
  if (state.data) {
    response = yield call(update, state.data.id, payload)
  } else {
    response = yield call(create, payload)
  }
  const { data, error } = response
  if (data) {
    yield put(success(data))
  } else {
    yield put(failed({ message: error.message }))
  }
}

function* searchEmployeeSaga({
  payload,
}: PayloadAction<SearchEmployeePayload>): SagaIterator {
  yield delay(500)
  yield put(loadEmployee(payload))
}

function* loadEmployeeSaga({
  payload,
}: PayloadAction<SearchEmployeePayload>): SagaIterator {
  const { data }: Response<Paged<Employee>> = yield call(loadEmployees, payload)
  if (data) {
    yield put(employees(data.data.map((it) => ({ ...it, label: it.name }))))
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLeading(load.type, loadDataSaga),
    takeLeading(submit.type, submitSaga),
    takeLatest(searchEmployee.type, searchEmployeeSaga),
    takeLatest(loadEmployee.type, loadEmployeeSaga),
  ])
}
