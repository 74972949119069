import type { User } from 'common-types'
import client, { tryRequest } from 'utils/axios'

export const identity = (token: string) => {
  return tryRequest(
    client.get<User>('auth/identity', {
      headers: { Authorization: `Bearer ${token}` },
    })
  )
}
