import { Paged } from 'common-types'
import client, { tryRequest } from 'utils/axios'
import type {
  Values,
  EmployeeContract,
  SearchEmployeePayload,
  Employee,
} from './slice'

export const loadData = (id: number) => {
  return tryRequest(client.get<EmployeeContract>(`employee-contract/${id}`))
}

export const create = (values: Values) => {
  return tryRequest(client.post<EmployeeContract>(`employee-contract`, values))
}

export const update = (id: number, values: Values) => {
  return tryRequest(
    client.patch<EmployeeContract>(`employee-contract/${id}`, values)
  )
}

export const loadEmployees = (params: SearchEmployeePayload) => {
  return tryRequest(
    client.get<Paged<Employee>>(`employee`, {
      params: { ...params, activeContract: true },
    })
  )
}
