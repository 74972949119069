import type { Saga, SagaMiddleware, Task } from 'redux-saga'

const sagaManager = (runSaga: SagaMiddleware) => {
  const sagas = new Map<string, Task>()
  const add = (key: string, saga: Saga) => {
    if (sagas.has(key)) {
      return
    }
    const task = runSaga.run(saga)
    sagas.set(key, task)
  }

  return { add }
}

export type SagaManager = ReturnType<typeof sagaManager>

export default sagaManager
