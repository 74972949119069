import createSagaMiddleware from '@redux-saga/core'
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import createReducerManager, { ReducerManager } from './redux/reducerManager'
import createSagaManager, { SagaManager } from './redux/sagaManager'

export interface ReducerManagerStore {
  readonly reducerManager: ReducerManager
}

export interface SagaManagerStore {
  readonly sagaManager: SagaManager
}

const createStore = () => {
  const reducerManager = createReducerManager({ counter: counterReducer })
  const sagaMiddleware = createSagaMiddleware()
  const sagaManager = createSagaManager(sagaMiddleware)
  const store = configureStore({
    reducer: reducerManager.reduce,
    middleware: (getMiddleware) => getMiddleware().concat(sagaMiddleware),
  })
  ;(store as unknown as { reducerManager: ReducerManager }).reducerManager =
    reducerManager
  ;(store as unknown as { sagaManager: SagaManager }).sagaManager = sagaManager
  return store
}

export const store = createStore()

export const reducerManager = (store as unknown as ReducerManagerStore)
  .reducerManager

export const sagaManager = (store as unknown as SagaManagerStore).sagaManager

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
