import React from 'react'
import ThemeProvider from 'theme/ThemeProvider'
import SessionProvider from 'features/session/SessionProvider'
import Routes from 'routes'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import BreadcrumbsContextProvider from 'context/Breadcrumbs'

const App = () => {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BreadcrumbsContextProvider>
          <CssBaseline />
          <SessionProvider>
            <Routes />
          </SessionProvider>
        </BreadcrumbsContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
