import type { PayloadAction } from '@reduxjs/toolkit'
import type { SagaIterator } from 'redux-saga'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import type { Response } from 'utils/axios'
import { create, loadData, update } from './services'
import {
  success,
  failed,
  Employee,
  load,
  Values,
  loaded,
  select as s,
  State,
  submit,
} from './slice'

function* loadDataSaga({ payload }: PayloadAction<number>): SagaIterator {
  const { data, error }: Response<Employee> = yield call(loadData, payload)
  if (data) {
    yield put(loaded(data))
  } else {
    yield put(failed({ message: error.message }))
  }
}

function* submitSaga({ payload }: PayloadAction<Values>): SagaIterator {
  const state: State = yield select(s)
  let response: Response<Employee>
  if (state.data) {
    response = yield call(update, state.data.id, payload)
  } else {
    response = yield call(create, payload)
  }
  const { data, error } = response
  if (data) {
    yield put(success(data))
  } else {
    yield put(failed({ message: error.message }))
  }
}

export default function* saga(): SagaIterator {
  yield all([
    takeLeading(load.type, loadDataSaga),
    takeLeading(submit.type, submitSaga),
  ])
}
