import { PayloadAction } from '@reduxjs/toolkit'
import type { Paged } from 'common-types'
import type { SagaIterator } from 'redux-saga'
import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import type { Response } from 'utils/axios'
import { loadData } from './services'
import {
  success,
  failed,
  Employee,
  load,
  Query,
  select as s,
  State,
  search,
} from './slice'

function* loadDataSaga({ payload }: PayloadAction<Query>): SagaIterator {
  const { data }: Response<Paged<Employee> & Query> = yield call(
    loadData,
    payload
  )
  if (data) {
    yield put(success(data))
  } else {
    yield put(failed())
  }
}

function* searchSaga({ payload }: PayloadAction<string>): SagaIterator {
  yield delay(500)
  const { perPage }: State = yield select(s)
  yield put(load({ page: 1, perPage, search: payload }))
}

export default function* saga(): SagaIterator {
  yield all([
    takeLeading(load.type, loadDataSaga),
    takeLatest(search.type, searchSaga),
  ])
}
