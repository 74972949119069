import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { User } from 'common-types'
import type { RootState } from 'app/store'
import { success, SuccessPayload } from 'features/login/slice'
import { logout } from 'features/logout/slice'

export interface AddActionPayload {
  readonly token: string
  readonly user: User
}

export interface State {
  token: string
  user?: User
  status: 'idle' | 'loading' | 'failed' | 'success' | 'removed'
}

const initialState: State = {
  token: '',
  status: 'idle',
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount: number) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    init: (state) => {
      state.status = 'loading'
    },
    add: (state, { payload }: PayloadAction<AddActionPayload>) => {
      state.status = 'success'
      state.user = payload.user
      state.token = payload.token
    },
    remove: (state) => {
      state.status = 'removed'
      state.user = undefined
      state.token = ''
    },
    failed: (state) => {
      state.status = 'failed'
      state.user = undefined
      state.token = ''
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(success, (state, { payload }: PayloadAction<SuccessPayload>) => {
        state.status = 'success'
        state.token = payload.token
        state.user = payload.user
      })
      .addCase(logout, (state) => {
        state.status = 'removed'
        state.token = ''
        state.user = undefined
      })
  },
})

export const { init, add, remove, failed } = slice.actions

export const select = (state: RootState) => state.session || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
