import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { slice, logout } from './slice'
import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useAppDispatch } from 'app/hooks'
import { Logout as LogoutIcon } from '@mui/icons-material'

export const Logout: FC = () => {
  useInjectReducer(slice.name, slice.reducer)
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={() => setOpen(true)}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Logout?</DialogTitle>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              setOpen(false)
              dispatch(logout())
            }}
            color="warning"
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Logout
