import { Box, Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { FC, SyntheticEvent, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { select, Employee, searchEmployee, ActiveContract } from './slice'
import { Form as F, FormikProps } from 'formik'
import Autocomplete from 'components/Autocomplete'
import DatePicker from 'components/DatePicker'

const FormikForm = styled(F)`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`

export interface Values {
  readonly employee: Employee | null
  readonly activeContract?: ActiveContract | null
  readonly startDate: Date | null
  readonly endDate: Date | null
}

export const Form: FC<FormikProps<Values>> = ({
  values,
  setFieldValue,
  handleSubmit,
  isSubmitting,
}) => {
  const { employees } = useAppSelector(select)
  const dispatch = useAppDispatch()

  const onEmployeeInputChange = useCallback(
    (_: SyntheticEvent, search: string) => {
      dispatch(searchEmployee({ id: values.employee?.id, search }))
    },
    [dispatch, values.employee]
  )

  useEffect(() => {
    if (values.employee) {
      setFieldValue('activeContract', values.employee.activeContract)
    } else {
      setFieldValue('activeContract', null)
    }
  }, [values.employee])

  return (
    <FormikForm onSubmit={handleSubmit}>
      <Autocomplete
        disablePortal
        options={employees}
        color="primary"
        disabled={isSubmitting}
        name="employee"
        label="Employee"
        onInputChange={onEmployeeInputChange}
        isOptionEqualToValue={(o, v) => o.id === v.id}
      />
      <DatePicker name="startDate" label="Start Date" />
      <DatePicker
        name="endDate"
        label="End Date"
        minDate={values.startDate || undefined}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          type="submit"
          sx={{ minWidth: (theme) => theme.spacing(20) }}
        >
          Save
        </Button>
        {isSubmitting && (
          <CircularProgress
            size={24}
            color="secondary"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </FormikForm>
  )
}

export default Form
