import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import * as yup from 'yup'
import { select, slice, submit } from './slice'
import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useInjectSaga } from 'app/hooks/useInjectSaga'
import saga from './saga'
import { useNavigate } from 'react-router-dom'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
})

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-top: 16px;
  }
`

interface Values {
  readonly email: string
  readonly password: string
}

export const Login: FC = () => {
  useInjectReducer(slice.name, slice.reducer)
  useInjectSaga(slice.name, saga)
  const { status } = useAppSelector(select)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  useEffect(() => {
    if (status === 'success') {
      navigate('/')
    }
  }, [status, navigate])

  const formik = useFormik<Values>({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: ({ email, password }) => {
      dispatch(submit({ username: email, password }))
    },
  })

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <TextField
          variant="filled"
          color="primary"
          label="Email"
          type="email"
          disabled={status === 'loading'}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.email && formik.touched.email}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          variant="filled"
          color="primary"
          label="Password"
          type="password"
          disabled={status === 'loading'}
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.password && formik.touched.password}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={status === 'loading'}
            type="submit"
            sx={{ minWidth: (theme) => theme.spacing(20) }}
          >
            Login
          </Button>
          {status === 'loading' && (
            <CircularProgress
              size={24}
              color="secondary"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Form>
    </Container>
  )
}

export default Login
