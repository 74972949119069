import type { PayloadAction } from '@reduxjs/toolkit'
import type { SagaIterator } from 'redux-saga'
import { all, call, put, takeLeading } from 'redux-saga/effects'
import type { Response } from 'utils/axios'
import { loadData } from './services'
import { failed, Employee, load, loaded } from './slice'

function* loadDataSaga({ payload }: PayloadAction<number>): SagaIterator {
  const { data, error }: Response<Employee> = yield call(loadData, payload)
  if (data) {
    yield put(loaded(data))
  } else {
    yield put(failed({ message: error.message }))
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLeading(load.type, loadDataSaga)])
}
