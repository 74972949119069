import { useEffect } from 'react'
import type { Saga } from 'redux-saga'
import { sagaManager } from '../store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-arrow/prefer-arrow-functions
export const useInjectSaga = function <A extends any[] = any[]>(
  key: string,
  saga: Saga<A>
) {
  useEffect(() => {
    sagaManager.add(key, saga as Saga)
  })
}
