import type { Action, Reducer } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { reducerManager } from '../store'

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const useInjectReducer = function <S, A extends Action<P>, P>(
  key: string,
  reducer: Reducer<S, A>
) {
  useEffect(() => {
    reducerManager.add(key, reducer as Reducer)
  })
}
