import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { select, slice, load, Values, submit, reset } from './slice'
import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useInjectSaga } from 'app/hooks/useInjectSaga'
import saga from './saga'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { BreadcrumbsContext, Crumb, HOME } from 'context/Breadcrumbs'

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`

const Loading = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup.string().required('Name is required'),
})

type Params = Record<'id' | 'from', string | undefined>

const crumbs: ReadonlyArray<Crumb> = [
  HOME,
  { href: '/employee', children: 'Employee' },
]

export const Index: FC = () => {
  const { setCrumbs } = useContext(BreadcrumbsContext)
  useInjectReducer(slice.name, slice.reducer)
  useInjectSaga(slice.name, saga)
  const params = useParams<Params>()
  const navigate = useNavigate()
  const id = params.id ? parseInt(params.id, 10) : 0
  const { data, status, errorMessage } = useAppSelector(select)
  const [prev, setPrev] = useState(status)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id <= 0) {
      setCrumbs([...crumbs, { href: `/employee/create`, children: 'Create' }])
    } else if (data && data.id === id) {
      setCrumbs([
        ...crumbs,
        { href: `/employee/edit/${id}`, children: data.name },
      ])
    } else {
      setCrumbs([...crumbs, { href: `/employee/edit/${id}`, children: 'Edit' }])
    }
  }, [data, id, setCrumbs])

  const formik = useFormik<Values>({
    initialValues:
      data && data.id === id
        ? { name: data.name, email: data.email }
        : { name: '', email: '' },
    validationSchema,
    onSubmit: (values) => {
      dispatch(submit(values))
    },
  })

  useEffect(() => {
    if (data && data.id === id && prev === 'loading' && status === 'idle') {
      formik.resetForm({ values: { name: data.name, email: data.email } })
    }
    setPrev(status)
  }, [prev, status, data, id, formik])

  useEffect(() => {
    if (id) {
      dispatch(load(id))
    }
    return () => {
      dispatch(reset())
    }
  }, [dispatch, id])

  const loading = status === 'loading' || (id && data && data.id !== id)

  return (
    <Container
      maxWidth="xl"
      sx={
        loading
          ? {
              minHeight: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : {
              minHeight: '100vh',
            }
      }
    >
      {loading ? (
        <Loading>
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Loading>
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <TextField
            variant="filled"
            color="primary"
            label="Name"
            type="text"
            disabled={status === 'submitting'}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.name && formik.touched.name}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            variant="filled"
            color="primary"
            label="Email"
            type="email"
            disabled={status === 'submitting'}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.email && formik.touched.email}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={status === 'submitting'}
              type="submit"
              sx={{ minWidth: (theme) => theme.spacing(20) }}
            >
              Save
            </Button>
            {status === 'submitting' && (
              <CircularProgress
                size={24}
                color="secondary"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Form>
      )}
      <Dialog
        open={status === 'success' || status === 'failed'}
        onClose={() => {
          if (status === 'failed') {
            dispatch(reset())
          } else if (status === 'success') {
            if (params.from) {
              navigate(params.from, { replace: true })
            } else {
              navigate(-1)
            }
          }
        }}
      >
        <DialogTitle>
          {status === 'success' ? 'Data saved successfully' : 'Error'}
        </DialogTitle>
        {errorMessage && (
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              if (status === 'failed') {
                dispatch(reset())
              } else if (status === 'success') {
                if (params.from) {
                  navigate(params.from, { replace: true })
                } else {
                  navigate(-1)
                }
              }
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Index
