import React, { FC } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import {
  DatePicker as A,
  DatePickerProps as AC,
} from '@mui/x-date-pickers/DatePicker'
import { useField } from 'formik'

export interface DatePickerProps
  extends Omit<
    AC<Date, Date>,
    'value' | 'onChange' | 'renderInput' | 'onChange'
  > {
  readonly name: string
  readonly label?: TextFieldProps['label']
}

export const DatePicker: FC<DatePickerProps> = ({ name, label, ...props }) => {
  const [field, meta, helper] = useField<AC<Date, Date>['value']>(name)
  return (
    <A
      {...props}
      onChange={(v) => helper.setValue(v)}
      value={field.value}
      onOpen={() => helper.setTouched(true)}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={meta.touched ? meta.error : undefined}
          error={meta.touched && !!meta.error}
          label={label}
          onBlur={() => helper.setTouched(true)}
        />
      )}
    />
  )
}

export default DatePicker
