import { useInjectReducer } from 'app/hooks/useInjectReducer'
import { useInjectSaga } from 'app/hooks/useInjectSaga'
import { FC, useEffect, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import saga from './saga'
import { select, init, slice } from './slice'

export interface SessionProviderProps {
  readonly children: ReactElement
}

export const SessionProvider: FC<SessionProviderProps> = ({
  children,
}: SessionProviderProps) => {
  useInjectReducer(slice.name, slice.reducer)
  useInjectSaga(slice.name, saga)
  const { status } = useSelector(select)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (status === 'idle') {
      dispatch(init())
    }
    if (status === 'removed' || status === 'failed') {
      navigate('/login')
    }
  }, [dispatch, status, navigate])
  return children
}

export default SessionProvider
