import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Paged, PagedState } from 'common-types'
import type { RootState } from 'app/store'

export interface Query {
  readonly page: number
  readonly perPage: number
  readonly search: string
}

export interface Employee {
  readonly id: number
  readonly name: string
  readonly email: string
}

export interface EmployeeContract {
  readonly id: number
  readonly employee: Employee
  readonly startDate: string
  readonly endDate: string
  readonly actualEndDate: string
}

export interface State {
  readonly page: number
  readonly perPage: number
  readonly search: string
  readonly data?: Paged<EmployeeContract> & Query
  readonly loading: boolean
}

const initialState: State = {
  page: 1,
  perPage: 20,
  search: '',
  loading: false,
}

export const slice = createSlice({
  name: 'employeeContractExpiring',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    load: (state, { payload }: PayloadAction<Query>) => {
      state.loading = true
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    search: (state, { payload }: PayloadAction<string>) => {
      //
    },
    success: (
      state,
      { payload }: PayloadAction<Paged<EmployeeContract> & Query>
    ) => {
      state.loading = false
      state.data = payload as PagedState<EmployeeContract> & Query
      state.page = payload.page
      state.perPage = payload.perPage
      state.search = payload.search
    },
    failed: (state) => {
      state.loading = false
    },
  },
})

export const { load, success, failed, search } = slice.actions

export const select = (state: RootState) =>
  state.employeeContractExpiring || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
