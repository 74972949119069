import type { User } from 'common-types'
import type { SagaIterator } from 'redux-saga'
import { all, call, put, takeLeading } from 'redux-saga/effects'
import type { Response } from 'utils/axios'
import { TOKEN } from 'utils/constants'
import { identity } from './services'
import { init, add, failed, remove } from './slice'

function* identitySaga(): SagaIterator {
  const token = localStorage.getItem(TOKEN)
  if (token) {
    const { data }: Response<User> = yield call(identity, token)
    if (data) {
      yield put(add({ token, user: data }))
    } else {
      yield put(failed())
    }
  } else {
    yield put(remove())
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLeading(init.type, identitySaga)])
}
