import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

export interface Values {
  readonly name: string
  readonly email: string
}

export interface Employee {
  readonly id: number
  readonly name: string
  readonly email: string
}

export interface FailedPayload {
  readonly message: string
}

export interface State {
  readonly data?: Employee
  readonly errorMessage?: string
  readonly status: 'idle' | 'loading' | 'submitting' | 'success' | 'failed'
}

const initialState: State = {
  status: 'idle',
}

export const slice = createSlice({
  name: 'employeeForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    load: (state, { payload }: PayloadAction<number>) => {
      state.status = 'loading'
      state.data = undefined
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submit: (state, { payload }: PayloadAction<Values>) => {
      state.status = 'submitting'
    },
    loaded: (state, { payload }: PayloadAction<Employee>) => {
      state.status = 'idle'
      state.data = payload
    },
    success: (state, { payload }: PayloadAction<Employee>) => {
      state.status = 'success'
      state.data = payload
    },
    failed: (state, { payload }: PayloadAction<FailedPayload>) => {
      state.status = 'failed'
      state.errorMessage = payload.message
    },
    reset: (state) => {
      state.status = 'idle'
      state.errorMessage = undefined
    },
  },
})

export const { load, loaded, submit, success, failed, reset } = slice.actions

export const select = (state: RootState) => state.employeeForm || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
