import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

export interface SearchEmployeePayload {
  readonly id?: number
  readonly search?: string
}

export interface Values {
  readonly employeeId: number
  readonly startDate: string
  readonly endDate: string
}

export interface ActiveContract {
  readonly id: number
  readonly startDate: string
  readonly endDate: string
  readonly actualEndDate?: string
}

export interface Employee {
  readonly id: number
  readonly name: string
  readonly label: string
  readonly activeContract?: ActiveContract
}

export interface EmployeeContract {
  readonly id: number
  readonly employee: Employee
  readonly startDate: string
  readonly endDate: string
  readonly actualEndDate?: string
}

export interface FailedPayload {
  readonly message: string
}

export interface State {
  readonly data?: EmployeeContract
  readonly errorMessage?: string
  readonly status: 'idle' | 'loading' | 'submitting' | 'success' | 'failed'

  readonly employees: ReadonlyArray<Employee>
}

const initialState: State = {
  status: 'idle',
  employees: [],
}

export const slice = createSlice({
  name: 'employeeContractForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    load: (state, { payload }: PayloadAction<number>) => {
      state.status = 'loading'
      state.data = undefined
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submit: (state, { payload }: PayloadAction<Values>) => {
      state.status = 'submitting'
    },
    loaded: (state, { payload }: PayloadAction<EmployeeContract>) => {
      state.status = 'idle'
      state.data = payload
    },
    success: (state, { payload }: PayloadAction<EmployeeContract>) => {
      state.status = 'success'
      state.data = payload
    },
    failed: (state, { payload }: PayloadAction<FailedPayload>) => {
      state.status = 'failed'
      state.errorMessage = payload.message
    },
    reset: (state) => {
      state.status = 'idle'
      state.errorMessage = undefined
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchEmployee: (_, { payload }: PayloadAction<SearchEmployeePayload>) => {
      //
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadEmployee: (_, { payload }: PayloadAction<SearchEmployeePayload>) => {
      //
    },
    employees: (state, { payload }: PayloadAction<ReadonlyArray<Employee>>) => {
      state.employees = payload as Employee[]
    },
  },
})

export const {
  load,
  loaded,
  submit,
  success,
  failed,
  reset,
  loadEmployee,
  searchEmployee,
  employees,
} = slice.actions

export const select = (state: RootState) =>
  state.employeeContractForm || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
