import { PayloadAction } from '@reduxjs/toolkit'
import type { SagaIterator } from 'redux-saga'
import { all, call, put, takeLeading } from 'redux-saga/effects'
import type { Response } from 'utils/axios'
import { TOKEN } from 'utils/constants'
import { login } from './services'
import { success, failed, SuccessPayload, submit, Values } from './slice'

function* loginSaga({ payload }: PayloadAction<Values>): SagaIterator {
  const { data }: Response<SuccessPayload> = yield call(login, payload)
  if (data) {
    localStorage.setItem(TOKEN, data.token)
    yield put(success(data))
  } else {
    yield put(failed())
  }
}

export default function* saga(): SagaIterator {
  yield all([takeLeading(submit.type, loginSaga)])
}
