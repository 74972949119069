import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

export type State = Record<never, never>

const initialState: State = {}

export const slice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    logout: () => {
      //
    },
  },
})

export const { logout } = slice.actions

export const select = (state: RootState) => state.logout || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
