import { Group, Summarize } from '@mui/icons-material'
import {
  Box,
  Divider,
  Drawer as D,
  DrawerProps as DP,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Logout from 'features/logout/Logout'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

const Item = styled(ListItem)`
  & > .active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    & > .MuiListItemIcon-root {
      color: ${({ theme }) => theme.palette.secondary.contrastText};
    }
    &:hover {
      background-color: ${({ theme }) => theme.palette.secondary.light};
    }
  }
`

export interface DrawerProps {
  readonly open: boolean
  readonly onClose: DP['onClose']
  readonly drawerWidth: number
}

const Drawer: FC<DrawerProps> = ({ open, onClose, drawerWidth }) => {
  const container = 'window' in global ? window.document.body : undefined

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <Item disablePadding>
          <ListItemButton component={NavLink} to="/employee">
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary="Employee" />
          </ListItemButton>
        </Item>
        <Item disablePadding>
          <ListItemButton component={NavLink} to="/employee-contract">
            <ListItemIcon>
              <Summarize />
            </ListItemIcon>
            <ListItemText primary="Contract" />
          </ListItemButton>
        </Item>
      </List>
      <Divider />
      <List>
        <Logout />
      </List>
    </div>
  )

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <D
        container={container}
        variant="temporary"
        open={open}
        onClose={onClose}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </D>
      <D
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </D>
    </Box>
  )
}

export default Drawer
