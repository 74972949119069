import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { User } from 'common-types'
import type { RootState } from 'app/store'

export interface Values {
  readonly username: string
  readonly password: string
}

export interface SuccessPayload {
  readonly user: User
  readonly token: string
}

export interface State {
  values?: Values
  status: 'idle' | 'loading' | 'failed' | 'success'
}

const initialState: State = {
  status: 'idle',
}

export const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    submit: (state, { payload }: PayloadAction<Values>) => {
      state.status = 'loading'
      state.values = payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    success: (state, _: PayloadAction<SuccessPayload>) => {
      state.status = 'success'
      state.values = undefined
    },
    failed: (state) => {
      state.status = 'failed'
      state.values = undefined
    },
  },
})

export const { submit, success, failed } = slice.actions

export const select = (state: RootState) => state.login || initialState

export type Reducer = typeof slice.reducer

export default slice.reducer
