import {
  Action,
  combineReducers,
  Reducer,
  ReducersMapObject,
} from '@reduxjs/toolkit'
import { RootReducer } from 'app/reducer'

const reducerManager = (initialReducers: ReducersMapObject) => {
  const reducers = { ...initialReducers }
  let combined = combineReducers(reducers)
  let keysToRemove: string[] = []

  const reduce = (
    state: Record<string, unknown> | undefined,
    action: Action
  ): Record<string, unknown> => {
    if (keysToRemove.length) {
      const s = { ...state }
      for (const key in keysToRemove) {
        delete s[key]
      }
      keysToRemove = []
      return combined(s, action)
    }
    return combined(state, action)
  }

  return {
    getMap: () => reducers,
    reduce: reduce as unknown as RootReducer,
    add: (key: string, reducer: Reducer) => {
      if (!key || reducers[key]) {
        return
      }
      reducers[key] = reducer
      combined = combineReducers(reducers)
    },
    remove: (key: string) => {
      if (!key || !reducers[key]) {
        return
      }
      delete reducers[key]
      keysToRemove.push(key)
      combined = combineReducers(reducers)
    },
  }
}

export type ReducerManager = ReturnType<typeof reducerManager>

export default reducerManager
