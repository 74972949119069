import client, { tryRequest, Paged } from 'utils/axios'
import type { Query, EmployeeContract } from './slice'

export const loadData = (params: Query) => {
  return tryRequest(
    client.get<Paged<EmployeeContract>>('employee-contract/expiring', {
      params,
    })
  )
}
