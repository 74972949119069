import { FormikValues } from 'formik'
import { useEffect } from 'react'
import type { FormikRef } from './useFormikRef'

export interface UseFormikSetSubmittingProps<V extends FormikValues> {
  readonly formikRef: FormikRef<V>
  readonly isSubmitting: boolean
}

export const useFormikSetSubmitting = <V extends FormikValues>(
  formikRef: FormikRef<V>,
  isSubmitting: boolean
): void => {
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setSubmitting(isSubmitting)
    }
  }, [isSubmitting, formikRef])
}
